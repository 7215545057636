import React, { useState } from "react";
import Web3 from 'web3';
import { Box, Button, Link,  Spacer, Text, Flex, Image } from '@chakra-ui/react';
import './Mintsea.css';
import Sea from '../assets/animationsea.gif';
import '../myCollections/MySeaCollection';
import ProgressbarSea from "./progressBar/ProgressbarSea";

import { CrossmintPayButton } from "@crossmint/client-sdk-react-ui";
import Clock from "../components/Clock";


const Mintsea = ({accounts, setAccounts, setContract, Contract, setWeb3w, web3w, setShowModalCDSea, showModalCDSea}) => {
	// console.log(web3w)
	const siosac = web3w;

    const openModalCDSea = () => { setShowModalCDSea(prev => !prev); };

	const [mintAmount, setMintAmount] = useState(1);
	const isConnected = Boolean(accounts[0]);
	const [Txhash, setTxhash] = useState([]);
	const [Txshow, setTxshow] = useState(false);
	const ABI = [
		{
			"inputs": [],
			"stateMutability": "nonpayable",
			"type": "constructor"
		},
		{
			"anonymous": false,
			"inputs": [
				{
					"indexed": true,
					"internalType": "address",
					"name": "owner",
					"type": "address"
				},
				{
					"indexed": true,
					"internalType": "address",
					"name": "approved",
					"type": "address"
				},
				{
					"indexed": true,
					"internalType": "uint256",
					"name": "tokenId",
					"type": "uint256"
				}
			],
			"name": "Approval",
			"type": "event"
		},
		{
			"anonymous": false,
			"inputs": [
				{
					"indexed": true,
					"internalType": "address",
					"name": "owner",
					"type": "address"
				},
				{
					"indexed": true,
					"internalType": "address",
					"name": "operator",
					"type": "address"
				},
				{
					"indexed": false,
					"internalType": "bool",
					"name": "approved",
					"type": "bool"
				}
			],
			"name": "ApprovalForAll",
			"type": "event"
		},
		{
			"anonymous": false,
			"inputs": [
				{
					"indexed": true,
					"internalType": "address",
					"name": "previousOwner",
					"type": "address"
				},
				{
					"indexed": true,
					"internalType": "address",
					"name": "newOwner",
					"type": "address"
				}
			],
			"name": "OwnershipTransferred",
			"type": "event"
		},
		{
			"anonymous": false,
			"inputs": [
				{
					"indexed": true,
					"internalType": "address",
					"name": "from",
					"type": "address"
				},
				{
					"indexed": true,
					"internalType": "address",
					"name": "to",
					"type": "address"
				},
				{
					"indexed": true,
					"internalType": "uint256",
					"name": "tokenId",
					"type": "uint256"
				}
			],
			"name": "Transfer",
			"type": "event"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "to",
					"type": "address"
				},
				{
					"internalType": "uint256",
					"name": "tokenId",
					"type": "uint256"
				}
			],
			"name": "approve",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "owner",
					"type": "address"
				}
			],
			"name": "balanceOf",
			"outputs": [
				{
					"internalType": "uint256",
					"name": "",
					"type": "uint256"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "baseExtension",
			"outputs": [
				{
					"internalType": "string",
					"name": "",
					"type": "string"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "baseURI",
			"outputs": [
				{
					"internalType": "string",
					"name": "",
					"type": "string"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "cost",
			"outputs": [
				{
					"internalType": "uint256",
					"name": "",
					"type": "uint256"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "uint256",
					"name": "tokenId",
					"type": "uint256"
				}
			],
			"name": "getApproved",
			"outputs": [
				{
					"internalType": "address",
					"name": "",
					"type": "address"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "owner",
					"type": "address"
				},
				{
					"internalType": "address",
					"name": "operator",
					"type": "address"
				}
			],
			"name": "isApprovedForAll",
			"outputs": [
				{
					"internalType": "bool",
					"name": "",
					"type": "bool"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "maxMintAmount",
			"outputs": [
				{
					"internalType": "uint256",
					"name": "",
					"type": "uint256"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "maxSupply",
			"outputs": [
				{
					"internalType": "uint256",
					"name": "",
					"type": "uint256"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "_to",
					"type": "address"
				},
				{
					"internalType": "uint256",
					"name": "_mintAmount",
					"type": "uint256"
				}
			],
			"name": "mint",
			"outputs": [],
			"stateMutability": "payable",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "name",
			"outputs": [
				{
					"internalType": "string",
					"name": "",
					"type": "string"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "owner",
			"outputs": [
				{
					"internalType": "address",
					"name": "",
					"type": "address"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "uint256",
					"name": "tokenId",
					"type": "uint256"
				}
			],
			"name": "ownerOf",
			"outputs": [
				{
					"internalType": "address",
					"name": "",
					"type": "address"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "bool",
					"name": "_state",
					"type": "bool"
				}
			],
			"name": "pause",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "paused",
			"outputs": [
				{
					"internalType": "bool",
					"name": "",
					"type": "bool"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "renounceOwnership",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "from",
					"type": "address"
				},
				{
					"internalType": "address",
					"name": "to",
					"type": "address"
				},
				{
					"internalType": "uint256",
					"name": "tokenId",
					"type": "uint256"
				}
			],
			"name": "safeTransferFrom",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "from",
					"type": "address"
				},
				{
					"internalType": "address",
					"name": "to",
					"type": "address"
				},
				{
					"internalType": "uint256",
					"name": "tokenId",
					"type": "uint256"
				},
				{
					"internalType": "bytes",
					"name": "_data",
					"type": "bytes"
				}
			],
			"name": "safeTransferFrom",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "operator",
					"type": "address"
				},
				{
					"internalType": "bool",
					"name": "approved",
					"type": "bool"
				}
			],
			"name": "setApprovalForAll",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "string",
					"name": "_newBaseExtension",
					"type": "string"
				}
			],
			"name": "setBaseExtension",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "string",
					"name": "_newBaseURI",
					"type": "string"
				}
			],
			"name": "setBaseURI",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "uint256",
					"name": "_newmaxMintAmount",
					"type": "uint256"
				}
			],
			"name": "setmaxMintAmount",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "bytes4",
					"name": "interfaceId",
					"type": "bytes4"
				}
			],
			"name": "supportsInterface",
			"outputs": [
				{
					"internalType": "bool",
					"name": "",
					"type": "bool"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "symbol",
			"outputs": [
				{
					"internalType": "string",
					"name": "",
					"type": "string"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "uint256",
					"name": "index",
					"type": "uint256"
				}
			],
			"name": "tokenByIndex",
			"outputs": [
				{
					"internalType": "uint256",
					"name": "",
					"type": "uint256"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "owner",
					"type": "address"
				},
				{
					"internalType": "uint256",
					"name": "index",
					"type": "uint256"
				}
			],
			"name": "tokenOfOwnerByIndex",
			"outputs": [
				{
					"internalType": "uint256",
					"name": "",
					"type": "uint256"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "uint256",
					"name": "tokenId",
					"type": "uint256"
				}
			],
			"name": "tokenURI",
			"outputs": [
				{
					"internalType": "string",
					"name": "",
					"type": "string"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "totalSupply",
			"outputs": [
				{
					"internalType": "uint256",
					"name": "",
					"type": "uint256"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "from",
					"type": "address"
				},
				{
					"internalType": "address",
					"name": "to",
					"type": "address"
				},
				{
					"internalType": "uint256",
					"name": "tokenId",
					"type": "uint256"
				}
			],
			"name": "transferFrom",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "newOwner",
					"type": "address"
				}
			],
			"name": "transferOwnership",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "_owner",
					"type": "address"
				}
			],
			"name": "walletOfOwner",
			"outputs": [
				{
					"internalType": "uint256[]",
					"name": "",
					"type": "uint256[]"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "withdraw",
			"outputs": [],
			"stateMutability": "payable",
			"type": "function"
		}
	]
	var account = null;
	var contract = null;
	const ADDRESS = "0xB6FdeBB98e1C55fe83e838F044a333BFfb384711";

	async function mint() {
		if (isConnected) {
			// var web3 = new Web3(window.ethereum);
			// await window.ethereum.send('eth_requestAccounts');
			// var accounts = await web3.eth.getAccounts();
			// account = accounts[0];
			var web3 = new Web3(web3w);
			contract = new web3.eth.Contract(ABI, ADDRESS);
			// console.log(accounts)
			// console.log(contract)

			setContract(contract)
			
			var _mintAmount = Number(document.querySelector("[name=amount]").value);
			var mintRate = Number(await contract.methods.cost().call());
			var totalAmount = mintRate * _mintAmount;
			contract.methods.mint(accounts, _mintAmount).send({ from: accounts, value: String(totalAmount) })

			.on("transactionHash", function(hash) {
				setTxhash(hash) 
				setTxshow(true);
				// console.log(hash);
			})
		}
	}

	const handleDrecrement = () => {
	if (mintAmount <= 1) return;
	setMintAmount(mintAmount - 1);
	};

	const handleIncrement = () => {
	if (mintAmount >= 10) return;
	setMintAmount(mintAmount + 1);
	};


    // const cont = ;


  return (
    <Flex justifyContent="center">
        <Box
         background={'rgba(244, 180, 147, 0.05)'}
         borderRadius="10"
         color="rgba(255, 255, 255, 0.7)"
        //  border="solid 1px"
		 className="filg85"
        >
            <Box>
                <Text align="center" fontFamily="Righteous" className="sdkfs">
                    <div>
                    <h1 className="olfdww">Minting is LIVE!</h1>
                    {/* <div className="eedqa"><Clock /></div> */}
                    <p>A collection of 1,200 Robiza Wine Token NFT's. Collectors have a chance to own one of a kind RobizaWineNFT with many fantastic, fun traits. There's something for everyone.</p>
                    <p>Your chance to get in on the ground floor of this exciting project that's ready for lift off!</p>
                    </div>
                </Text>
            </Box>

            <Flex className="fleifkso">
                <Box className="bolsaa" textAlign="center">
                    <Image src={Sea} borderRadius="5" className="idksp"/>
                </Box>

                <Box
                    background={'rgba(244, 180, 147, 0.05)'}
                    borderRadius="10"
                    color="rgba(255, 255, 255, 0.7)"
                    height= "fit-content"
                    border="solid 1px"
                    margin="auto"
					className="ddf52s"
                >
                    <Flex className="folssgd" fontFamily="Righteous">
                        <Box marginBottom="5px" className="sdcsacas" >
                            <h6 className="h6d5">Mint Price</h6>
                            <h5 className="h6d51">0.1 ETH</h5>
                        </Box>
                        <Box className="sdcsacas">
                            <h6 className="h6d5">Sale Status</h6>
                            <h5 className="h6d51">Open</h5>
                        </Box>
                    </Flex>
					<ProgressbarSea />
                    {!isConnected ? (
					<Box>
						<Box
							fontFamily="Righteous"
							color='rgba(255, 255, 255, 0.7)'
							textAlign="center"
							marginTop="20px"
						>
							<h4>PLEASE<br/>Connect Wallet</h4>
						</Box>
						<Box margin={'5px 0 15px 0'} fontWeight={'500'}>
							or
						</Box>
						<Box 
                        >
                            {/* <input disabled name="amount" className="inpuif" type="number" value={mintAmount} />
						<CrossmintPayButton
                            collectionPhoto="null"  
							className="xmint-btn"
							clientId="d2e36290-ad3f-40a2-811a-458953d75609"
							environment="staging"
							mintConfig={{   
								type: "erc-721",
								_mintAmount: mintAmount,
								totalPrice: "0.01"
								// your custom minting arguments...
							}}
						/> */}
							<button type="button" onClick={openModalCDSea} className="btn btn-primary shadow-none">Buy with Credit Card</button>
						</Box>
					</Box>
                    ) : (
                        <Box>
                            <Flex marginTop="10px" justifyContent="space-around">
                                <Flex width="30%">
                                    <button type="button" className="btn btn-primary shadow-none " onClick={handleDrecrement}>-</button>
                                    <input disabled name="amount" className="inpuif" type="number" value={mintAmount} />
                                    <button type="button" className="btn btn-primary shadow-none " onClick={handleIncrement}>+</button>
                                </Flex>
                                <Spacer />
                                <Flex width="70%">
                                    <button onClick={mint} type="button" className="btn btn-primary shadow-none w-100">Mint</button>
                                </Flex>
                                {/* <BtnMintSea /> */}
                            </Flex>
                            <Box fontFamily="Righteous" fontSize="18px" marginTop="5px">
                                QTY Maximum - 10
                            </Box>
							{Txshow &&
								<Box textAlign="center" fontFamily="Righteous" fontSize="16px">
									Transaction Hash: <br></br>{Txhash}
									{/* <button onClick={() => {navigator.clipboard.writeText(this.state.textToCopy)}}></button> */}
								</Box>
							}
							{!Txshow &&
								<div></div>
							}
                        </Box>
                    )}
                </Box>
            </Flex>
        </Box>
    </Flex>
   );
};

export default Mintsea;