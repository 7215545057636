import React from 'react'
import { Box, Card, Flex, Image } from '@chakra-ui/react';

const NFTContainer = ({checked, nfts, showModalSeaCard, showModalSunCard, showModalMoonCard, setShowModalSeaCard, setShowModalSunCard, setShowModalMoonCard, modalData, setModalData}) => {

  const openModal = () => {
    if (checked === 1) {
     return setShowModalSeaCard(prev => !prev);
    }
    else if (checked === 2) {
     return setShowModalSunCard(prev => !prev);
    } 
    else {
      return setShowModalMoonCard(prev => !prev);
    }
  };
    
  return (
        <Flex  style={{display: "inline-grid",gridTemplateColumns: "repeat(4, 5fr)"}}>
          {nfts.map((nft, index) =>  {
              return (
                <Card
                    key={nft.tokenId}  
                    onClick={()=> { setModalData(nft); openModal(); }}
                    _hover={{ color: "rgba(255, 255, 255, 0.7)", background: "rgba(255, 255, 255, 0.05)", transform: "scale(1.04)", transition: "transform .2s ease-in-out" }}
                    width="200px"
                    margin="25px"
                    overflow='hidden'
                    border="solid 1px rgba(244, 180, 147, 0.05)"
                    variant='outline' 
                    background={'rgba(255, 255, 255, 0.05)'} 
                    borderRadius="5" 
                    color={'rgba(255, 255, 255, 0.7)'}
                    className=""
                  >
                        <Image src={nft.img} boxSize="" borderRadius="10px"padding="5px"/>
                    <Card background={'rgba(255, 255, 255, 0.15)'} color="rgba(255, 255, 255, 0.7)" borderWidth={'0px'}  borderRadius="5" margin="5px" textAlign="center">Rank: #{nft.rank}</Card>
                    <Box className="" padding="10px" textAlign="left">
                        <h6 className="">RobizaWineNFT</h6>
                        <h6 className="">Sea #{nft.tokenId}</h6>
                    </Box>
                </Card>
            )
          })}
    </Flex>
  )
}

export default NFTContainer