import React, { useRef, useEffect, useCallback, useState } from 'react';
import { Box, Button, Link,  Spacer, Text, Flex, Image } from '@chakra-ui/react';
import { CrossmintPayButton } from "@crossmint/client-sdk-react-ui";

import { useSpring, animated } from 'react-spring';
import styled from 'styled-components';


const Background = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
`;

const ModalWrapper = styled.div`
  width: 400px;
  height: 250px;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
  color: #000;
  display: grid;
  position: relative;
  z-index: 10;
  border-radius: 10px;
`;

const ModalImg = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background: #000;
`;

const ModalContent = styled.div`
  border-radius: 10px;
  padding: 30px 50px;
  /* margin-top: -20px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1.8;
  color: #141414;
  background: radial-gradient(circle, rgba(66,23,131,1) 15%, rgba(3,1,22,1) 100%) !important;

  /* background-color: #41332b !important; */
  p {
    margin-bottom: 1rem;
  }
  button {
    /* padding: 10px 24px; */
    /* background: rgba(243, 151, 106, 0.3) !important; */
    font-size: 17px !important;
    font-weight: 400;
  }
  h1{
    color: rgba(255,255,255,0.7);
    font-size: 30px;
    /* margin-bottom: 10px; */
    margin-top: 10px;
  }
  input{
    border-radius: 5px;
    /* margin: 0 20px 0 20px; */
      /* font-size: 20px; */
    color: rgba(255,255,255,0.7);
    height: fit-content;
    width: 50px;
    text-align: center;
    background: transparent;
  }
`;



export const ModalCDSun = ({ showModalCDSun, setShowModalCDSun }) => {

  // const ModalCDSun = ({ showModalCDSun, setShowModalCDSun}) => {

  const [mintAmount, setMintAmount] = useState(1);

  const modalRef = useRef();

  const animation = useSpring({
    config: {
      duration: 250
    },
    opacity: showModalCDSun ? 1 : 0,
    transform: showModalCDSun ? `translateY(-50%)` : `translateY(-100%)`
  });

  const closeModal = e => {
    if (modalRef.current === e.target) {
      setShowModalCDSun(false);
    }
  };

  const keyPress = useCallback(
    e => {
      if (e.key === 'Escape' && showModalCDSun) {
        setShowModalCDSun(false);
        // console.log('I pressed');
      }
    },
    [setShowModalCDSun, showModalCDSun]
  );

  useEffect(
    () => {
      document.addEventListener('keydown', keyPress);
      return () => document.removeEventListener('keydown', keyPress);
    },
    [keyPress]
  );


	const handleDrecrement = () => {
    if (mintAmount <= 1) return;
    setMintAmount(mintAmount - 1);
  };

  const handleIncrement = () => {
    if (mintAmount >= 10) return;
    setMintAmount(mintAmount + 1);
  };

  const totalpri = String(mintAmount * 0.2);
  

  return (
    <>
    {showModalCDSun ? (
      <Background onClick={closeModal} ref={modalRef}>
        <animated.div style={animation}>
          <ModalWrapper showModalCDSun={showModalCDSun}>
            <ModalContent>
                          <Box marginTop="" justifyContent="">
                                <Flex>
                                  <h1>Mint your NFTs with Credit Card</h1>
                                </Flex>
                                <Spacer />
                                <Flex margin="40px" background={""} justifyContent={"center"}>
                                    <Button 
                                    background={"transparent"}
                                    size={"sm"} type="button" className="btn btn-primary shadow-none btn-sm" onClick={handleDrecrement}>-</Button>
                                    <input disabled name="amount" className="" type="number" value={mintAmount} />
                                    <Button size={"sm"} type="button" className="btn btn-primary shadow-none " onClick={handleIncrement}>+</Button>
                                </Flex>
                                <Spacer />
                                <Flex width="">
                                  <CrossmintPayButton
                                    collectionPhoto="null"  
                                    className="xmint-btn"
                                    clientId="023dbd0f-aa87-4fb7-83e3-73e07129a9af"
                                    environment="production"
                                    mintConfig={{   
                                      type: "erc-721",
                                      _mintAmount: mintAmount,
                                      totalPrice: totalpri
                                      // your custom minting arguments...
                                    }}
                                  />
                                </Flex>
                                {/* <BtnMintSea /> */}
                            </Box >
              </ModalContent>
            </ModalWrapper>
          </animated.div>
        </Background>
      ) : null}
    </>
  )
}

export default ModalCDSun