import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Box, Flex, } from '@chakra-ui/react';


const StakingNFT = () => {
  return (
    <Flex 
      justifyContent="center"
      marginTop="200px"
    >
      <Box 
        letterSpacing="1px" 
        fontFamily="Righteous"
        color='rgba(255, 255, 255, 0.7)' 
        fontWeight={500}
        textAlign="center"
      >
        <h1 className='h1fol'>StakingNFT is coming Soon!</h1>
        <p className='h1fol2'>follow our roadmap and social media to stay updated</p>
      </Box>
    </Flex>
    );
};

export default StakingNFT;