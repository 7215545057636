// import { useState } from "react";
import React from 'react';
import { Box, Button, Flex, Image, Link, Menu, MenuButton, MenuList, MenuItem, useDisclosure } from '@chakra-ui/react';
import { Link as RouterLink } from "react-router-dom";
import { RxDiscordLogo, RxTwitterLogo, RxInstagramLogo } from "react-icons/rx";
// import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { AiOutlineMenu } from "react-icons/ai";
import Logo from "../assets/2.png";
import './Navbar.css';
import Web3 from 'web3';
import Web3Modal from "web3modal";
import { ethers } from "ethers";
import WalletConnect from "@walletconnect/web3-provider";
import CoinbaseWalletSDK from "@coinbase/wallet-sdk";


const Navbar = ({accounts, setAccounts, showModal, setShowModal, setShowModal1, setContract, setWeb3w, balanceSea, setBalanceSea, balanceSun, setBalanceSun, balanceMoon, setBalanceMoon, setBalanceAcc, balanceAcc}) => {

    const account = (
            accounts[0] +
            accounts[1] +
            accounts[2] +
            accounts[3] +
            accounts[4] +
            accounts[5] +
            "..." +
            accounts[38] +
            accounts[39] +
            accounts[40] +
            accounts[41]
        );
    const connect = Boolean(accounts[0]);
    const { isOpen, onOpen, onClose } = useDisclosure()
    const openModalMenu = () => { setShowModal1(prev => !prev); };
    const openModal = () => { setShowModal(prev => !prev); };
	const ABI = [
		{
			"inputs": [],
			"stateMutability": "nonpayable",
			"type": "constructor"
		},
		{
			"anonymous": false,
			"inputs": [
				{
					"indexed": true,
					"internalType": "address",
					"name": "owner",
					"type": "address"
				},
				{
					"indexed": true,
					"internalType": "address",
					"name": "approved",
					"type": "address"
				},
				{
					"indexed": true,
					"internalType": "uint256",
					"name": "tokenId",
					"type": "uint256"
				}
			],
			"name": "Approval",
			"type": "event"
		},
		{
			"anonymous": false,
			"inputs": [
				{
					"indexed": true,
					"internalType": "address",
					"name": "owner",
					"type": "address"
				},
				{
					"indexed": true,
					"internalType": "address",
					"name": "operator",
					"type": "address"
				},
				{
					"indexed": false,
					"internalType": "bool",
					"name": "approved",
					"type": "bool"
				}
			],
			"name": "ApprovalForAll",
			"type": "event"
		},
		{
			"anonymous": false,
			"inputs": [
				{
					"indexed": true,
					"internalType": "address",
					"name": "previousOwner",
					"type": "address"
				},
				{
					"indexed": true,
					"internalType": "address",
					"name": "newOwner",
					"type": "address"
				}
			],
			"name": "OwnershipTransferred",
			"type": "event"
		},
		{
			"anonymous": false,
			"inputs": [
				{
					"indexed": true,
					"internalType": "address",
					"name": "from",
					"type": "address"
				},
				{
					"indexed": true,
					"internalType": "address",
					"name": "to",
					"type": "address"
				},
				{
					"indexed": true,
					"internalType": "uint256",
					"name": "tokenId",
					"type": "uint256"
				}
			],
			"name": "Transfer",
			"type": "event"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "to",
					"type": "address"
				},
				{
					"internalType": "uint256",
					"name": "tokenId",
					"type": "uint256"
				}
			],
			"name": "approve",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "owner",
					"type": "address"
				}
			],
			"name": "balanceOf",
			"outputs": [
				{
					"internalType": "uint256",
					"name": "",
					"type": "uint256"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "baseExtension",
			"outputs": [
				{
					"internalType": "string",
					"name": "",
					"type": "string"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "baseURI",
			"outputs": [
				{
					"internalType": "string",
					"name": "",
					"type": "string"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "cost",
			"outputs": [
				{
					"internalType": "uint256",
					"name": "",
					"type": "uint256"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "uint256",
					"name": "tokenId",
					"type": "uint256"
				}
			],
			"name": "getApproved",
			"outputs": [
				{
					"internalType": "address",
					"name": "",
					"type": "address"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "owner",
					"type": "address"
				},
				{
					"internalType": "address",
					"name": "operator",
					"type": "address"
				}
			],
			"name": "isApprovedForAll",
			"outputs": [
				{
					"internalType": "bool",
					"name": "",
					"type": "bool"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "maxMintAmount",
			"outputs": [
				{
					"internalType": "uint256",
					"name": "",
					"type": "uint256"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "maxSupply",
			"outputs": [
				{
					"internalType": "uint256",
					"name": "",
					"type": "uint256"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "_to",
					"type": "address"
				},
				{
					"internalType": "uint256",
					"name": "_mintAmount",
					"type": "uint256"
				}
			],
			"name": "mint",
			"outputs": [],
			"stateMutability": "payable",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "name",
			"outputs": [
				{
					"internalType": "string",
					"name": "",
					"type": "string"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "owner",
			"outputs": [
				{
					"internalType": "address",
					"name": "",
					"type": "address"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "uint256",
					"name": "tokenId",
					"type": "uint256"
				}
			],
			"name": "ownerOf",
			"outputs": [
				{
					"internalType": "address",
					"name": "",
					"type": "address"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "bool",
					"name": "_state",
					"type": "bool"
				}
			],
			"name": "pause",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "paused",
			"outputs": [
				{
					"internalType": "bool",
					"name": "",
					"type": "bool"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "renounceOwnership",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "from",
					"type": "address"
				},
				{
					"internalType": "address",
					"name": "to",
					"type": "address"
				},
				{
					"internalType": "uint256",
					"name": "tokenId",
					"type": "uint256"
				}
			],
			"name": "safeTransferFrom",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "from",
					"type": "address"
				},
				{
					"internalType": "address",
					"name": "to",
					"type": "address"
				},
				{
					"internalType": "uint256",
					"name": "tokenId",
					"type": "uint256"
				},
				{
					"internalType": "bytes",
					"name": "_data",
					"type": "bytes"
				}
			],
			"name": "safeTransferFrom",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "operator",
					"type": "address"
				},
				{
					"internalType": "bool",
					"name": "approved",
					"type": "bool"
				}
			],
			"name": "setApprovalForAll",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "string",
					"name": "_newBaseExtension",
					"type": "string"
				}
			],
			"name": "setBaseExtension",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "string",
					"name": "_newBaseURI",
					"type": "string"
				}
			],
			"name": "setBaseURI",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "uint256",
					"name": "_newmaxMintAmount",
					"type": "uint256"
				}
			],
			"name": "setmaxMintAmount",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "bytes4",
					"name": "interfaceId",
					"type": "bytes4"
				}
			],
			"name": "supportsInterface",
			"outputs": [
				{
					"internalType": "bool",
					"name": "",
					"type": "bool"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "symbol",
			"outputs": [
				{
					"internalType": "string",
					"name": "",
					"type": "string"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "uint256",
					"name": "index",
					"type": "uint256"
				}
			],
			"name": "tokenByIndex",
			"outputs": [
				{
					"internalType": "uint256",
					"name": "",
					"type": "uint256"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "owner",
					"type": "address"
				},
				{
					"internalType": "uint256",
					"name": "index",
					"type": "uint256"
				}
			],
			"name": "tokenOfOwnerByIndex",
			"outputs": [
				{
					"internalType": "uint256",
					"name": "",
					"type": "uint256"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "uint256",
					"name": "tokenId",
					"type": "uint256"
				}
			],
			"name": "tokenURI",
			"outputs": [
				{
					"internalType": "string",
					"name": "",
					"type": "string"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "totalSupply",
			"outputs": [
				{
					"internalType": "uint256",
					"name": "",
					"type": "uint256"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "from",
					"type": "address"
				},
				{
					"internalType": "address",
					"name": "to",
					"type": "address"
				},
				{
					"internalType": "uint256",
					"name": "tokenId",
					"type": "uint256"
				}
			],
			"name": "transferFrom",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "newOwner",
					"type": "address"
				}
			],
			"name": "transferOwnership",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "_owner",
					"type": "address"
				}
			],
			"name": "walletOfOwner",
			"outputs": [
				{
					"internalType": "uint256[]",
					"name": "",
					"type": "uint256[]"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "withdraw",
			"outputs": [],
			"stateMutability": "payable",
			"type": "function"
		}
	]
	const AddressSea = "0xB6FdeBB98e1C55fe83e838F044a333BFfb384711";
    const AddressSun = "0xeC7fd0a171eF4F6781768E99f1278357ce49f973";
	const AddressMoon = "0x476a31cA7A3942C48644817AfbA7496916FdFa11";
    const providerOptions = {
        walletconnect: {
          package: WalletConnect, // required
          options: {
            infuraId:  "f2c84c4eb46f49348e7307355991967c"// required
          }
        },
        coinbasewallet: {
          package: CoinbaseWalletSDK, // Required
          options: {
            appName: "Coinbase", // Required
            infuraId: "f2c84c4eb46f49348e7307355991967c", // Required
            chainId: 1, //4 for Rinkeby, 1 for mainnet (default)
          },
        },
      };
      const web3Modal = new Web3Modal({
        network: "ethereum",
        theme: "dark", // optional, 'dark' / 'light',
        cacheProvider: false, // optional
        providerOptions, // required
      });
      var contractSea = null;
      var contractSun = null;
      var contractMoon = null;
      const connectWeb3Wallet = async () => {
        try {
          const web3Provider = await web3Modal.connect();
          const library = new ethers.providers.Web3Provider(web3Provider);
          const web3Accounts = await library.listAccounts();
		  
          const chainId = 0x1;
          if (web3Accounts.length === 0) {
            console.log("Please connect to MetaMask!");
          } else if(chainId !== '0x1') {
            await window.ethereum.request({
              method: 'wallet_switchEthereumChain',
              params: [{ chainId: '0x1' }],
          })
          setAccounts(web3Accounts[0])
          }  

		  var web3 = new Web3(web3Provider);
		  setWeb3w(web3Provider)
		  var balanceEth = await library.getBalance(web3Accounts[0]);
		  balanceEth = web3.utils.fromWei(String(balanceEth), 'ether')
		  setBalanceAcc(balanceEth)
		  contractSea = new web3.eth.Contract(ABI, AddressSea);
		  const balanceSea = Number(await contractSea.methods.balanceOf(web3Accounts[0]).call())
		//   console.log("balanceSea", balanceSea)
		  setBalanceSea(balanceSea)
		  contractSun = new web3.eth.Contract(ABI, AddressSun);
		  const balanceSun = Number(await contractSun.methods.balanceOf(web3Accounts[0]).call())
		  // console.log("balanceSun", balanceSun)
		  setBalanceSun(balanceSun)
		  contractMoon = new web3.eth.Contract(ABI, AddressMoon);
		  const balanceMoon = Number(await contractMoon.methods.balanceOf(web3Accounts[0]).call())
		  // console.log("balanceMoon", balanceMoon)
		  setBalanceMoon(balanceMoon) 
        } catch (error) {
          console.log(error);
        }
      };
    
      const disconnectWeb3Modal = async () => {
        await web3Modal.clearCachedProvider();
        window.localStorage.clear();
        setAccounts([]);
      };
    

    return (
        <Flex className="flepo" align="center" backgroundColor="rgba(195, 123, 87, 0.1)">
            <Flex className="owdvz" backgroundColor="">
                <Link href='https://robizawinetoken.com/' _hover={{ textDecoration: "none" }}>
                    <Box className="bofinav" letterSpacing="1px" fontFamily="Righteous" color='rgba(255, 255, 255, 0.7)' fontWeight={500}>
                        <Image src={Logo} boxSize="32px" className="fjdkv" />
                        RobizaWineNFT
                    </Box>
                </Link>
            </Flex>

            {/* Center Side - Sections and Connect  */}
            <Flex justify="space-around" align="center" width="50%" padding="2px" className="flexbarras">
                <Menu isOpen={isOpen} >
                    <MenuButton
                        variant="ghost"
						_hover={{ color: "rgba(255, 255, 255, 0.8)", background: "rgba(255, 255, 255, 0.1)" }}
						borderColor={"transparent"}
						background={"transparent"}
                        color={'rgba(255, 255, 255, 0.7)'}
                        onMouseEnter={onOpen}
                        onMouseLeave={onClose}
                        borderRadius={5}
                        padding={'3px 15px 3px 15px'}
						fontSize="16px"
						size="sm"
						fontWeight={'500'}
						fontFamily="Righteous"
                    >
                        Collections 
						{/* {isOpen ? <IoIosArrowUp /> : <IoIosArrowDown />} */}
                    </MenuButton>
                    <MenuList
                        onMouseEnter={onOpen} 
                        onMouseLeave={onClose}
                        width="140px"
						// _hover={{ color: "rgba(255, 255, 255, 0.8)", background: "rgba(255, 255, 255, 0.1)" }}
						borderColor={"transparent"}
						background="rgba(255, 255, 255, 0.1)"
                        borderRadius={5}
						fontFamily="Righteous"
						className="olr85dd"
                        >
                            <MenuItem 
                                borderRadius={5}
								_hover={{ color: "rgba(255, 255, 255, 0.8)", background: "rgba(255, 255, 255, 0.1)" }}
								borderColor={"transparent"}
								background={"transparent"}
                                color={'rgba(255, 255, 255, 0.7)'}
                                justifyContent={'center'}
                                fontSize={'16px'}
                                // fontWeight={'400'}
                                // className={"btn-primary"}
                                marginBottom={'5px'}
                                as={RouterLink} 
                                to='/seacollection'
                                padding={'3px 15px 3px 15px'}
                            >
                                SEA
                            </MenuItem>
                            <MenuItem 
                                borderRadius={5}
								_hover={{ color: "rgba(255, 255, 255, 0.8)", background: "rgba(255, 255, 255, 0.1)" }}
								borderColor={"transparent"}
								background={"transparent"}
                                color={'rgba(255, 255, 255, 0.7)'}
                                justifyContent={'center'}
                                fontSize={'16px'}
                                fontWeight={'400'}
                                // className={"btn-primary"}
                                marginBottom={'5px'}
                                as={RouterLink} 
                                to='/suncollection'
                                padding={'3px 15px 3px 15px'}
                            >
                                SUN
                            </MenuItem>
                            <MenuItem
                                borderRadius={5}
								_hover={{ color: "rgba(255, 255, 255, 0.8)", background: "rgba(255, 255, 255, 0.1)" }}
								borderColor={"transparent"}
								background={"transparent"}
                                color={'rgba(255, 255, 255, 0.7)'}
                                justifyContent={'center'}
                                fontSize={'16px'}
                                fontWeight={'400'}
                                // className={"btn-primary"}
                                marginBottom={'5px'}
                                as={RouterLink} 
                                to='/mooncollection'
                                padding={'3px 15px 3px 15px'}
                            >
                                MOON
                            </MenuItem>
                    </MenuList>
                </Menu>
                {/* <Spacer /> */}
                <Button 
                    textDecoration="none"   
                    color={'rgba(255, 255, 255, 0.7)'}
					_hover={{ color: "rgba(255, 255, 255, 0.8)", background: "rgba(255, 255, 255, 0.1)" }}
					borderColor={"transparent"}
					background={"transparent"}
					as={RouterLink} 
                    to='/mint'
                    borderRadius={5}
                    padding={'3px 15px 3px 15px'}
					fontWeight={'500'}
					fontSize="16px"
					fontFamily="Righteous"
					size="sm"
                >   
                    Mint 
                </Button>
                <Button 
                    textDecoration="none"   
                    color={'rgba(255, 255, 255, 0.7)'}
					_hover={{ color: "rgba(255, 255, 255, 0.8)", background: "rgba(255, 255, 255, 0.1)" }}
					borderColor={"transparent"}
					background={"transparent"}
					as={RouterLink} 
                    to='/myrobizawineNFT'
                    borderRadius={5}
                    padding={'3px 15px 3px 15px'}
					size="sm"
					fontWeight={'500'}
					fontSize="16px"
					fontFamily="Righteous"
                >   
                    My RobizaWineNFT
                </Button>
                <Button 
                    textDecoration="none"   
                    color={'rgba(255, 255, 255, 0.7)'}
					_hover={{ color: "rgba(255, 255, 255, 0.8)", background: "rgba(255, 255, 255, 0.1)" }}
					borderColor={"transparent"}
					background={"transparent"}
					as={RouterLink} 
                    to='/stakingNFT'
                    borderRadius={5}
                    padding={'3px 15px 3px 15px'}
					size="sm"
					fontWeight={'500'}
					fontSize="16px"
					fontFamily="Righteous"
                >   
                    Staking(Soon)
                </Button>
            </Flex>

            <Flex className="fleido5">
                {connect &&
                <button onClick={openModal} type="button" className="btn btn-primary shadow-none btn-sm">{account}</button>
                }
                {!connect &&
                <button  onClick={connectWeb3Wallet} className="btn btn-primary shadow-none btn-sm">Connect Wallet</button>
                }
                <button onClick={openModalMenu} className="btn btn-primary shadow-none btn-sm butnf"><AiOutlineMenu /></button>
            </Flex>
            {/* Right Side - Social Meida Icons  */}
            <Flex justify="space-around" padding="12px" className="fled52">
                <Link href="https://discord.gg/mq7WyPGC" target="_blank">
                    <RxDiscordLogo size={'2rem'} color={'rgba(255, 255, 255, 0.7)'} onMouseOver={({target})=>target.style.color="rgba(255, 255, 255, 0.8)"} onMouseOut={({target})=>target.style.color="rgba(255, 255, 255, 0.7)"}/>   
                </Link>
                <Link href="https://twitter.com/RWinetoken" target="_blank">
                    <RxTwitterLogo size={'2rem'} color={'rgba(255, 255, 255, 0.7)'} onMouseOver={({target})=>target.style.color="rgba(255, 255, 255, 0.8)"} onMouseOut={({target})=>target.style.color="rgba(255, 255, 255, 0.7)"}/>   
                </Link>
                <Link href="https://www.instagram.com/robizawinenft/" target="_blank">
                    <RxInstagramLogo size={'2rem'} color={'rgba(255, 255, 255, 0.7)'} onMouseOver={({target})=>target.style.color="rgba(255, 255, 255, 0.8)"} onMouseOut={({target})=>target.style.color="rgba(255, 255, 255, 0.7)"}/>   
                </Link>
				<Link as={RouterLink} to='/WHITEPAPER-V1.1.pdf' target="_blank">
					<svg class="text-white" fill="rgba(255, 255, 255, 0.7)" width="2rem" height="2rem" viewBox="0 0 24 24" role="img" xmlns="http://www.w3.org/2000/svg"><path d="M10.802 17.77a.703.703 0 1 1-.002 1.406.703.703 0 0 1 .002-1.406m11.024-4.347a.703.703 0 1 1 .001-1.406.703.703 0 0 1-.001 1.406m0-2.876a2.176 2.176 0 0 0-2.174 2.174c0 .233.039.465.115.691l-7.181 3.823a2.165 2.165 0 0 0-1.784-.937c-.829 0-1.584.475-1.95 1.216l-6.451-3.402c-.682-.358-1.192-1.48-1.138-2.502.028-.533.212-.947.493-1.107.178-.1.392-.092.62.027l.042.023c1.71.9 7.304 3.847 7.54 3.956.363.169.565.237 1.185-.057l11.564-6.014c.17-.064.368-.227.368-.474 0-.342-.354-.477-.355-.477-.658-.315-1.669-.788-2.655-1.25-2.108-.987-4.497-2.105-5.546-2.655-.906-.474-1.635-.074-1.765.006l-.252.125C7.78 6.048 1.46 9.178 1.1 9.397.457 9.789.058 10.57.006 11.539c-.08 1.537.703 3.14 1.824 3.727l6.822 3.518a2.175 2.175 0 0 0 2.15 1.862 2.177 2.177 0 0 0 2.173-2.14l7.514-4.073c.38.298.853.461 1.337.461A2.176 2.176 0 0 0 24 12.72a2.176 2.176 0 0 0-2.174-2.174"></path></svg>
				</Link>
			</Flex>
        </Flex>	
    );
}

export default Navbar;