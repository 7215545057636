import { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MyRobizaWineNFT from './views/MyRobizaWineNFT';
import StakingNFT from './views/StakingNFT';
import SeaCollection from './views/Sea';
import SunCollection from './views/Sunset';
import MoonCollection from './views/Moon';
import Mint from './views/Mint';
import Mintsea from './mint/Mintsea';
import Mintsunset from './mint/Mintsunset';
import Mintmoon from './mint/Mintmoon';
import Home from './Home';
import { ModalMenu } from './modals/ModalMenu';
import { ModalConnect } from './modals/ModalConnect';
import { ModalSeaCard } from './modals/ModalSeaCard';
import { ModalSunCard } from './modals/ModalSunCard';
import { ModalMoonCard } from './modals/ModalMoonCard';
import ModalCDSea from './mint/buyCreditCard/ModalCDSea';
import ModalCDMoon from './mint/buyCreditCard/ModalCDMoon';
import ModalCDSun from './mint/buyCreditCard/ModalCDSun';


function App() {
  const [accounts, setAccounts] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [showModalSeaCard, setShowModalSeaCard] = useState(false);
  const [showModalSunCard, setShowModalSunCard] = useState(false);
  const [showModalMoonCard, setShowModalMoonCard] = useState(false);
  const [nfts] = useState([])
  const [modalData, setModalData] = useState([]);
  const [Contract, setContract] = useState([]);
  const [web3w, setWeb3w] = useState([]);
  const [balanceSea, setBalanceSea] = useState(); 
  const [balanceSun, setBalanceSun] = useState(); 
  const [balanceMoon, setBalanceMoon] = useState(); 
  const [balanceAcc, setBalanceAcc] = useState(); 
  const [showModalCDSea, setShowModalCDSea] = useState(false); 
  const [showModalCDSun, setShowModalCDSun] = useState(false); 
  const [showModalCDMoon, setShowModalCDMoon] = useState(false); 


  return (  
    <Router>
      <div className='App'>
        <Navbar balanceAcc={balanceAcc} setBalanceAcc={setBalanceAcc} balanceSea={balanceSea} setBalanceSea={setBalanceSea} balanceSun={balanceSun} setBalanceSun={setBalanceSun} balanceMoon={balanceMoon} setBalanceMoon={setBalanceMoon} web3w={web3w} setWeb3w={setWeb3w} setContract={setContract} Contract={Contract} accounts={accounts} setAccounts={setAccounts} showModal={showModal} setShowModal={setShowModal} showModal1={showModal1} setShowModal1={setShowModal1}/>
        <ModalMenu accounts={accounts} setAccounts={setAccounts} showModal1={showModal1} setShowModal1={setShowModal1}/>
        <ModalConnect balanceAcc={balanceAcc} setBalanceAcc={setBalanceAcc} balanceSea={balanceSea} setBalanceSea={setBalanceSea} balanceSun={balanceSun} setBalanceSun={setBalanceSun} balanceMoon={balanceMoon} setBalanceMoon={setBalanceMoon} accounts={accounts} setAccounts={setAccounts} showModal={showModal} setShowModal={setShowModal}/>
        <ModalSeaCard nfts={nfts} modalData={modalData} setModalData={setModalData} showModalSeaCard={showModalSeaCard} setShowModalSeaCard={setShowModalSeaCard}/>
        <ModalSunCard nfts={nfts} modalData={modalData} setModalData={setModalData} showModalSunCard={showModalSunCard} setShowModalSunCard={setShowModalSunCard}/>
        <ModalMoonCard nfts={nfts} modalData={modalData} setModalData={setModalData} showModalMoonCard={showModalMoonCard} setShowModalMoonCard={setShowModalMoonCard}/>

        <ModalCDSea setShowModalCDSea={setShowModalCDSea} showModalCDSea={showModalCDSea}/>
        <ModalCDSun setShowModalCDSun={setShowModalCDSun} showModalCDSun={showModalCDSun}/>
        <ModalCDMoon setShowModalCDMoon={setShowModalCDMoon} showModalCDMoon={showModalCDMoon}/>
      </div>
    <Routes>
        <Route path='/' element={<Home/>} />
        <Route path='/seacollection' element={<SeaCollection/>} />
        <Route path='/suncollection' element={<SunCollection/>} />
        <Route path='/mooncollection' element={<MoonCollection/>} />
        <Route path='/mint' element={<Mint/>} />
        <Route path='/mintsea' element={<Mintsea web3w={web3w} setWeb3w={setWeb3w} setContract={setContract} Contract={Contract} accounts={accounts} setAccounts={setAccounts} setShowModalCDSea={setShowModalCDSea} showModalCDSea={showModalCDSea}/>} />
        <Route path='/mintsun' element={<Mintsunset web3w={web3w} setWeb3w={setWeb3w} setContract={setContract} Contract={Contract} accounts={accounts} setAccounts={setAccounts} setShowModalCDSun={setShowModalCDSun} showModalCDSun={showModalCDSun}/>} />
        <Route path='/mintmoon' element={<Mintmoon web3w={web3w} setWeb3w={setWeb3w} setContract={setContract} Contract={Contract} accounts={accounts} setAccounts={setAccounts} setShowModalCDMoon={setShowModalCDMoon} showModalCDMoon={showModalCDMoon}/>} />
        <Route path='/myrobizawineNFT' element={<MyRobizaWineNFT web3w={web3w} setWeb3w={setWeb3w} nfts={nfts} modalData={modalData} setModalData={setModalData} accounts={accounts} setAccounts={setAccounts} showModalSeaCard={showModalSeaCard} setShowModalSeaCard={setShowModalSeaCard} showModalSunCard={showModalSunCard} setShowModalSunCard={setShowModalSunCard} showModalMoonCard={showModalMoonCard} setShowModalMoonCard={setShowModalMoonCard}/>} />
        <Route path='/stakingNFT' element={<StakingNFT/>} />
      </Routes>
    </Router>
  );
}

export default App;
