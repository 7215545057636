import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Box, Button, Text, Flex, Image } from '@chakra-ui/react';
import Sunset from '../assets/animationsun.gif';
import './Collections.css';
import { Link as RouterLink } from "react-router-dom";


const SunsetCollection = () => {
  return (
    <div>
        <Flex className='forltjd5'>
            <Box background={'transparent'} borderRadius="5" className='olfww'>
                <Box textAlign="center" color="rgba(255, 255, 255, 0.7)">
                    <h1 class="sad85dss"><b>SUN</b> Collection</h1>
                </Box>
                <Box color="rgba(255, 255, 255, 0.7)" fontSize="16px" fontWeight="400" fontFamily="Righteous">
                    <Text>
                        SUN is a collection of 1,000 NTFs that aim to unify people with web3.<br></br><br></br>
                        SUN collection grants access to various advantages and benefits within our ecosystem SUN collection. 
                        Besides, Holders can participate in exclusive events, such as NFT claims, raffles, giveaways, and much, much more. 
                        Don't forget that all SUN collection is special, but some are extremely unique.
                    </Text>
                </Box>
            </Box>
        </Flex>

        <Flex className='askca'>
            {/* <Box className='olrg522'> */}
            <div className='plolff'>
                <Image src={Sunset} borderRadius="5" className='orlgid5q'/>
                </div>
            {/* </Box> */}

            <Box className='savvvcs' justifyContent="center">
                <h2 class="h2fx">Utility and Benefits</h2>
                <p class="p12fqq">In addition to the benefits mentioned for the previous SEA membership, the following are added:</p><br></br>
                <p class="p12fq">10% on the reservation of villas and yachts in Ibiza. View conditions, contact us to make your booking.</p><br></br>
                <p class="p12fq">10% Discounts on Drink home Ibiza orders, 24h luxury drinks delivery to your villa and yacht during your stay in Ibiza.</p><br></br>
                <p class="p12fq">Gift of 1 Robiza Wine Bottle (to choose between Sun, Sea, Moon) during your stay in Ibiza.</p>               
                <Box textAlign="center" marginTop="25px">
                    <Button as={RouterLink} to='/mintsun' type="button" class="btn btn-primary shadow-none">Mint Now !</Button>
                </Box>
            </Box>
        </Flex>
    </div>
    );
};

export default SunsetCollection;