import React, { useState, useEffect } from 'react'
import { Box, Button, Link, Text, Flex, Image } from '@chakra-ui/react';
import { Link as RouterLink } from "react-router-dom";


const Clock = () => {
    const [timerDays, setTimerDays] = useState();
    const [timerHours, setTimerHours] = useState();
    const [timerMinutes, setTimerMinutes] = useState();
    const [timerSeconds, setTimerSeconds] = useState();

    let interval;

    const startTimer = () => {
        const countDownDate = new Date("2023-05-01T00:00Z").getTime();

        interval = setInterval(() => {
            const now = new Date().getTime();
            const distance = countDownDate - now;
            
            const days = Math.floor(distance / (24 * 60 * 60 * 1000));
            const hours = Math.floor((distance % (24 * 60 * 60 * 1000)) / (1000 * 60 * 60));
            const minutes = Math.floor((distance % (60 * 60 * 1000)) / (1000 * 60));
            const seconds = Math.floor((distance % (60 * 1000)) / 1000);

            if(distance < 0) {
                // Stop Timer
                // clearInterval(interval.current);
                setTimerDays('0');
                setTimerHours('0');
                setTimerMinutes('0');
                setTimerSeconds('0');
            } else {
                // Update Timer
                setTimerDays(days > 9 ? days : '0' + days);
                setTimerHours(hours > 9 ? hours : '0'+ hours);
                setTimerMinutes(minutes > 9 ? minutes : '0' + minutes);
                setTimerSeconds(seconds > 9 ? seconds : '0' + seconds);
            }
         })
    }

    useEffect(() => {
        startTimer();
    });


  return (
    <Box className='olf85esss' background={'rgba(244, 180, 147, 0.05)'}
    borderRadius="10"
    color="rgba(255, 255, 255, 0.7)">
       <Box className='olf85esss1'>
           <h1>MINTING</h1>
       </Box>
       <Box className='olf85esss21'>
           <Box className='oledas'>{timerDays}</Box>
           <Box className='oledas'>{timerHours}</Box>
           <Box className='oledas'>{timerMinutes}</Box>
           <Box className='oledas'>{timerSeconds}</Box>             
       </Box>
       <Box className='olf85esss22'>
           <Box className='oledas'>Days</Box>
           <Box className='oledas'>Hours</Box>
           <Box className='oledas'>Mins</Box>
           <Box className='oledas'>Secs</Box>              
       </Box>
   </Box>  )
}

export default Clock