import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Box, Button, Flex, Image } from '@chakra-ui/react';
import { Link as RouterLink } from "react-router-dom";
import './Mint.css';
import Moon from '../assets/animationmoon.gif';
import Sunset from '../assets/animationsun.gif';
import Sea from '../assets/animationsea.gif';


const Mint = () => {
  return (

    <div className='fodliv'>
      <Flex
          className='nufod'
      >
          <div background={'rgba(244, 180, 147, 0.05)'} borderRadius="5" className='box1fp'>
              <Image src={Sea} borderRadius="5" className='bofimg' />
              <h2 className="god85"><span>SEA</span> Collection</h2>
              <Button as={RouterLink} to='/mintsea' type="button" className="btn btn-primary shadow-none">
                MINT
              </Button>
          </div>
          <Box background={'rgba(244, 180, 147, 0.05)'} borderRadius="5" className='box1fp'>
              <Image src={Sunset} borderRadius="5" className='bofimg'/>
              <h2 className="god85"><span>SUN</span> Collection</h2>
              <Button as={RouterLink} to='/mintsun' type="button" className="btn btn-primary shadow-none">
                MINT
                </Button>
          </Box>
          <Box background={'rgba(244, 180, 147, 0.05)'} borderRadius="5" className='box1fp'>
              <Image src={Moon} borderRadius="5" className='bofimg'/>
              <h2 className="god85"><span>MOON</span> Collection</h2>
              <Button as={RouterLink} to='/mintmoon' type="button" className="btn btn-primary shadow-none">
                MINT
                </Button>
          </Box>
      </Flex>
    </div>
    );
};

export default Mint;