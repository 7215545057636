import React, { useState } from 'react';
import './MyRobizaWineNFT.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Box, Button, Flex } from '@chakra-ui/react';
import MySeaCollection from '../myCollections/MySeaCollection';
import MySunsetCollection from '../myCollections/MySunsetCollection';
import MyMoonCollection from '../myCollections/MyMoonCollection';

const MyRobizaWineNFT = ({accounts, setAccounts, showModalSeaCard, setShowModalSeaCard, showModalSunCard, setShowModalSunCard, showModalMoonCard, setShowModalMoonCard, nfts, modalData, setModalData, setWeb3w, web3w, balanceSun, balanceMoon}) => {
  const [checked, setChecked] = useState(1);
  const isConnected = Boolean(accounts[0]);


  return (
    <Box marginTop="70px">
      <Flex justifyContent="center">
        <Box 
          letterSpacing="1px" 
          fontFamily="Righteous"
          color='rgba(255, 255, 255, 0.7)' 
          fontWeight={500}
          textAlign="center"
        >
          <h1 className='h1fol'>My RobizaWine<b>NFT</b></h1>
        </Box>
      </Flex>

      <Box
        letterSpacing="1px" 
        fontFamily="Righteous"
        color='rgba(255, 255, 255, 0.7)' 
        fontWeight={500}
        textAlign="center"
        marginTop="30px"
        fontSize="30px"
      >
        Collections
      </Box>

      <Flex 
        justifyContent="center"
        letterSpacing="1px" 
        fontFamily="Righteous"
        color='rgba(255, 255, 255, 0.7)' 
        textAlign="center"
        marginTop="30px"
        fontSize="24px"
      >
        <Button 
          _hover={{ color: "rgba(255, 255, 255, 0.8)", background: "rgba(255, 255, 255, 0.1)" }}
          _focus={{ color: "rgba(255, 255, 255, 0.8)", background: "rgba(255, 255, 255, 0.1)" }}
          borderColor={"transparent"}
          background={"transparent"}
          color={'rgba(255, 255, 255, 0.7)'}
          borderRadius={5}
          onClick={() => setChecked(1)}
        >SEA</Button> 
        <span className='span52'>|</span> 
        <Button 
          _hover={{ color: "rgba(255, 255, 255, 0.8)", background: "rgba(255, 255, 255, 0.1)" }}
          _focus={{ color: "rgba(255, 255, 255, 0.8)", background: "rgba(255, 255, 255, 0.1)" }}
          borderColor={"transparent"}
          background={"transparent"}
          color={'rgba(255, 255, 255, 0.7)'}
          borderRadius={5}
          onClick={() => setChecked(2)}
        >SUN</Button> 
        <span className='span52'>|</span> 
        <Button 
          _hover={{ color: "rgba(255, 255, 255, 0.8)", background: "rgba(255, 255, 255, 0.1)" }}
          _focus={{ color: "rgba(255, 255, 255, 0.8)", background: "rgba(255, 255, 255, 0.1)" }}
          borderColor={"transparent"}
          background={"transparent"}
          color={'rgba(255, 255, 255, 0.7)'}
          borderRadius={5}
          onClick={() => setChecked(3)}
        >MOON</Button> 
      </Flex>
      <hr className='divider' />
      <Flex 
        justifyContent="center" 
        border="solid 1px"  
        borderColor="rgba(255, 255, 255, 0.7)"
        borderRadius="10"
        className='fikf'
      >
        <Box fontFamily="Righteous" color='rgba(255, 255, 255, 0.7)' >
            { !isConnected ? (
              <Box
                  fontFamily="Righteous"
                  color='rgba(255, 255, 255, 0.7)'
                  textAlign="center"
                  margin="20px"
              >
                  <h4>PLEASE<br/>Connect Wallet</h4>
              </Box>
            ) : (
              <div>
               { checked === 1 ?
                    <MySeaCollection checked={checked} web3w={web3w} setWeb3w={setWeb3w} nfts={nfts} modalData={modalData} setModalData={setModalData} showModalSeaCard={showModalSeaCard} setShowModalSeaCard={setShowModalSeaCard} />
                  : checked === 2 ?
                    <MySunsetCollection checked={checked} web3w={web3w} setWeb3w={setWeb3w} nfts={nfts} modalData={modalData} setModalData={setModalData} showModalSunCard={showModalSunCard} setShowModalSunCard={setShowModalSunCard} balanceSun={balanceSun}/>
                  : checked === 3 ?
                    <MyMoonCollection checked={checked} web3w={web3w} setWeb3w={setWeb3w} nfts={nfts} modalData={modalData} setModalData={setModalData} showModalMoonCard={showModalMoonCard} setShowModalMoonCard={setShowModalMoonCard} balanceMoon={balanceMoon}/>
                  :
                  <div></div>
                }
              </div>
            )}
        </Box>
      </Flex>
    </Box>
  );
}

export default MyRobizaWineNFT;