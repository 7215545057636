import React, { useRef, useEffect, useCallback } from 'react';
import { useSpring, animated } from 'react-spring';
import styled from 'styled-components';
import { Box, Card, Image } from '@chakra-ui/react';

const Background = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
`;

const ModalWrapper = styled.div`
  width: 850px;
  height: 250px;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
  color: #000;
  position: relative;
  z-index: 10;
  border-radius: 10px;
`;

const ModalContent = styled.div`
  border-radius: 10px;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1.8;
  color: #141414;
  background: radial-gradient(circle, rgba(66,23,131,1) 15%, rgba(3,1,22,1) 100%) !important;
  
  p {
    margin-bottom: 1rem;
  }
  button {
    padding: 10px 24px;
    background: rgba(243, 151, 106, 0.3) !important;
    font-size: 17px !important;
    font-weight: 500;
  }
  h3 {
    font-size: 29px;
    margin-bottom: 10px;
    font-weight: 500;

  }
`;

export const ModalSunCard = ({ nfts, showModalSunCard, setShowModalSunCard, modalData }) => {
  // console.log(modalData)
  const dataNftModal = new Object(modalData);
  // const dataNftModal = {modalData}
  const modalRef = useRef();
  const animation = useSpring({
    config: {
      duration: 250
    },
    opacity: showModalSunCard ? 1 : 0,
    transform: showModalSunCard ? `translateY(-50%)` : `translateY(-100%)`
  });

  const closeModal = e => {
    if (modalRef.current === e.target) {
      setShowModalSunCard(false);
    }
  };

  const keyPress = useCallback(
    e => {
      if (e.key === 'Escape' && showModalSunCard) {
        setShowModalSunCard(false);
        // console.log('I pressed');
      }
    },
    [setShowModalSunCard, showModalSunCard]
  );

  useEffect(
    () => {
      document.addEventListener('keydown', keyPress);
      return () => document.removeEventListener('keydown', keyPress);
    },
    [keyPress]
  );

  return (
    <>
      {showModalSunCard ? (
        <Background onClick={closeModal} ref={modalRef}>
          <animated.div style={animation}>
            <ModalWrapper showModalSunCard={showModalSunCard}>
              <ModalContent>
                <Box className="" padding="10px" width="50%">
                    <Image src={dataNftModal.img} boxSize="350px" borderRadius="10px"/>
                </Box>
                <Card width="50%"  background={'transparent'}>    
                <Box className="" textAlign="center" color="rgba(255, 255, 255, 0.7)" borderColor="rgba(255, 255, 255, 0.7)">
                    <h3 className="">RobizaWineNFT SUN #{dataNftModal.tokenId}</h3>
                </Box>    
                <Box className="" margin="10px 20px 10px 20px" textAlign="center" color="rgba(255, 255, 255, 0.7)" border="solid 1px" borderRadius="10px" fontSize="20px" fontWeight="500">
                    RANK {dataNftModal.rank}
                </Box> 
                  <Box textAlign="left" marginLeft="20px" marginTop="10px" color="rgba(255, 255, 255, 0.7)" fontSize={'17px'} letterSpacing={'1px'}>         
                    <span className=""><b>Background:</b> {dataNftModal.background}</span><br></br>
                    {/* <span className=""><b>Eye:</b> {dataNftModal.eye}</span><br></br> */}
                    <span className=""><b>Face:</b> {dataNftModal.face}</span><br></br>
                    <span className=""><b>Glasses:</b> {dataNftModal.glasses}</span><br></br>
                    {/* <span className=""><b>Pants:</b> {dataNftModal.pants}</span><br></br> */}
                    <span className=""><b>Shirt:</b> {dataNftModal.shirt}</span><br></br>
                    <span className=""><b>Head:</b> {dataNftModal.head}</span><br></br>
                    <span className=""><b>Accessories:</b> {dataNftModal.accessories}</span>
                  </Box>   
                </Card>
              </ModalContent>
            </ModalWrapper>
          </animated.div>
        </Background>
      ) : null}
    </>
  );
};