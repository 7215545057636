import React, { useRef, useEffect, useCallback } from 'react';
import { useSpring, animated } from 'react-spring';
import styled from 'styled-components';
import { Box, Button, Link,  Spacer, Text, Flex, Image } from '@chakra-ui/react';
import { Link as RouterLink } from "react-router-dom";
import { 
  RxDiscordLogo,
  RxTwitterLogo,
  RxInstagramLogo
} from "react-icons/rx";


const Background = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
`;

const ModalWrapper = styled.div`
  width: 350px;
  height: 250px;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
  color: #000;
  display: grid;
  position: relative;
  z-index: 10;
  border-radius: 10px;
`;

const ModalImg = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background: #000;
`;

const ModalContent = styled.div`
  border-radius: 10px;
  /* padding: 30px; */
  margin-top: -20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1.8;
  color: #141414;
  background: radial-gradient(circle, rgba(66,23,131,1) 15%, rgba(3,1,22,1) 100%) !important;

  /* background-color: #41332b !important; */
  p {
    margin-bottom: 1rem;
  }
  button {
    padding: 10px 24px;
    background: rgba(243, 151, 106, 0.3) !important;
    font-size: 17px !important;
    font-weight: 500;
  }
  h1{
    color: rgba(255,255,255,0.7);
    font-size: 35px;
    margin-bottom: 20px;
    margin-top: 10px;
  }
`;

export const ModalMenu = ({ showModal1, setShowModal1 }) => {
  const modalRef = useRef();

  const animation = useSpring({
    config: {
      duration: 250
    },
    opacity: showModal1 ? 1 : 0,
    transform: showModal1 ? `translateY(-50%)` : `translateY(-100%)`
  });

  const closeModal = e => {
    if (modalRef.current === e.target) {
      setShowModal1(false);
    }
  };

  const keyPress = useCallback(
    e => {
      if (e.key === 'Escape' && showModal1) {
        setShowModal1(false);
        // console.log('I pressed');
      }
    },
    [setShowModal1, showModal1]
  );

  useEffect(
    () => {
      document.addEventListener('keydown', keyPress);
      return () => document.removeEventListener('keydown', keyPress);
    },
    [keyPress]
  );

  return (
    <>
      {showModal1 ? (
        <Background onClick={closeModal} ref={modalRef}>
          <animated.div style={animation}>
            <ModalWrapper showModal1={showModal1}>
              <ModalContent>
                <h1>RobizaWineNFT</h1>
                <Button 
                    textDecoration="none"   
                    border={'solid'}
                    borderWidth={1}
                    as={RouterLink} 
                    to='/seacollection'
                    borderRadius={5}
                    padding={'3px 15px 3px 15px'}
                    onClick={ () => setShowModal1(prev => !prev) }
                    width={250}
                    margin={2}
                    _hover={{ color: "rgba(255, 255, 255, 0.8)", background: "rgba(255, 255, 255, 0.1)" }}
                    background={"transparent"}
                    color={'rgba(255, 255, 255, 0.7)'}
                >   
                    SEA Collection
                </Button>
                <Button 
                    textDecoration="none"   
                    border={'solid'}
                    borderWidth={1}
                    as={RouterLink} 
                    to='/suncollection'
                    borderRadius={5}
                    padding={'3px 15px 3px 15px'}
                    onClick={ () => setShowModal1(prev => !prev) }
                    width={250}
                    margin={2}
                    _hover={{ color: "rgba(255, 255, 255, 0.8)", background: "rgba(255, 255, 255, 0.1)" }}
                    background={"transparent"}
                    color={'rgba(255, 255, 255, 0.7)'}
                >   
                    SUN Collection
                </Button>
                <Button 
                    textDecoration="none"   
                    border={'solid'}
                    borderWidth={1}
                    as={RouterLink} 
                    to='/mooncollection'
                    borderRadius={5}
                    padding={'3px 15px 3px 15px'}
                    onClick={ () => setShowModal1(prev => !prev) }
                    width={250}
                    margin={2}
                    _hover={{ color: "rgba(255, 255, 255, 0.8)", background: "rgba(255, 255, 255, 0.1)" }}
                    background={"transparent"}
                    color={'rgba(255, 255, 255, 0.7)'}
                >   
                    MOON Collection
                </Button>
                <Button 
                    textDecoration="none"   
                    border={'solid'}
                    borderWidth={1}
                    as={RouterLink} 
                    to='/mint'
                    borderRadius={5}
                    padding={'3px 15px 3px 15px'}
                    onClick={ () => setShowModal1(prev => !prev) }
                    width={250}
                    margin={2}
                    _hover={{ color: "rgba(255, 255, 255, 0.8)", background: "rgba(255, 255, 255, 0.1)" }}
                    background={"transparent"}
                    color={'rgba(255, 255, 255, 0.7)'}
                >   
                    Mint
                </Button>
                <Button 
                    textDecoration="none"   
                    border={'solid'}
                    borderWidth={1}
                    as={RouterLink} 
                    to='/myrobizawineNFT'
                    borderRadius={5}
                    padding={'3px 15px 3px 15px'}
                    onClick={ () => setShowModal1(prev => !prev) }
                    width={250}
                    margin={2}
                    _hover={{ color: "rgba(255, 255, 255, 0.8)", background: "rgba(255, 255, 255, 0.1)" }}
                    background={"transparent"}
                    color={'rgba(255, 255, 255, 0.7)'}
                >   
                    My RobizaWineNFT
                </Button>
                <Button 
                    textDecoration="none"   
                    border={'solid'}
                    borderWidth={1}
                    as={RouterLink} 
                    to='/stakingNFT'
                    borderRadius={5}
                    padding={'3px 15px 3px 15px'}
                    onClick={ () => setShowModal1(prev => !prev) }
                    width={250}
                    margin={2}
                    _hover={{ color: "rgba(255, 255, 255, 0.8)", background: "rgba(255, 255, 255, 0.1)" }}
                    background={"transparent"}
                    color={'rgba(255, 255, 255, 0.7)'}
                >   
                    Staking(Soon)
                </Button>

                <Box marginTop={5} display={'flex'}>
                  <Link href="https://discord.gg/VNJQ42ss" target="_blank" margin={5}>
                      <RxDiscordLogo  size={'2rem'} color={'rgba(255, 255, 255, 0.7)'} onMouseOver={({target})=>target.style.color="rgba(255, 255, 255, 0.8)"} onMouseOut={({target})=>target.style.color="rgba(255, 255, 255, 0.7)"}/>   
                  </Link>
                  <Link href="https://twitter.com/RWinetoken" target="_blank" margin={5}>
                      <RxTwitterLogo size={'2rem'} color={'rgba(255, 255, 255, 0.7)'} onMouseOver={({target})=>target.style.color="rgba(255, 255, 255, 0.8)"} onMouseOut={({target})=>target.style.color="rgba(255, 255, 255, 0.7)"}/>   
                  </Link>
                  <Link href="https://www.instagram.com/robizawinenft/" target="_blank" margin={5}>
                      <RxInstagramLogo size={'2rem'} color={'rgba(255, 255, 255, 0.7)'} onMouseOver={({target})=>target.style.color="rgba(255, 255, 255, 0.8)"} onMouseOut={({target})=>target.style.color="rgba(255, 255, 255, 0.7)"}/>   
                  </Link>
                </Box>
              </ModalContent>
            </ModalWrapper>
          </animated.div>
        </Background>
      ) : null}
    </>
  );
};