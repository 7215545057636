import React, { Component } from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { Box } from '@chakra-ui/react';
import axios from 'axios';
// import './App.css';


const ADDRESS = "0xCadCc2B444485174987667d61BB8D6bCcae7494a";
const apikey = "Y4AIAPBA2CH5G7MTQ1E4HYQWU23ZK9SUCR";
const endpoint = "https://api-goerli.etherscan.io/api";

class ProgressbarMoon extends Component {
    constructor() {
        super();
        this.state = {
            balance: [],
            porcent: [],
        };
    }

    async componentDidMount() {
        await axios.get((endpoint + `?module=stats&action=tokensupply&contractaddress=${ADDRESS}&apikey=${apikey}`))
            .then(outputa => {
                const { result } = outputa.data
                this.setState({
                    balance: result,
                })
                this.setState({
                    porcent: (((result) / 100) * 100).toFixed(0),
                })
                //  console.log(outputa.data)
            })
    }

    render() {
        const { balance } = this.state;
        const { porcent } = this.state;

        return (
            <Box>
                <div>
                    <div className="fs-6 mb-1 text-end procid85"> 0% minted (0/ 800)</div>
                    <ProgressBar variant='progrend' animated style={{ height: "5px" }} now='0' />
                </div>
                {/* <div>
                    <div className="fs-6 mb-1 text-end procid85"> {porcent}% minted ({balance} / 800)</div>
                    <ProgressBar variant='progrend' animated style={{ height: "5px" }} now={balance} />
                </div> */}
            </Box>
        );
    }
};

export default ProgressbarMoon;