import React, { useRef, useEffect, useCallback, useState } from 'react';
import { useSpring, animated } from 'react-spring';
import styled from 'styled-components';
import { Box, Button, Link,  Spacer, Text, Flex, Image } from '@chakra-ui/react';

import Web3Modal from "web3modal";
import { ethers } from "ethers";
import WalletConnect from "@walletconnect/web3-provider";
import CoinbaseWalletSDK from "@coinbase/wallet-sdk";

import { 
  FiLogOut
} from "react-icons/fi";

import { 
  BiCopy
} from "react-icons/bi";



const Background = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
`;

const ModalWrapper = styled.div`
  width: 100%;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
  color: #000;
  display: grid;
  position: relative;
  z-index: 10;
  border-radius: 10px;
  border-style: none;
  border-color: rgba(255, 255, 255, 0.7)  ;
  /* border-width: 1px; */
`;

const ModalImg = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 10px 0 0 10px;
  background: #000;
`;

const ModalContent = styled.div`
  border-radius: 10px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1.8;
  color: #141414;
  background: radial-gradient(circle, rgba(66,23,131,1) 15%, rgba(3,1,22,1) 100%) !important;

  /* background-color: #41332b !important; */

  p {
    margin-bottom: 1rem;
  }
  button {
    padding: 5px 15px;
    font-size: 15px !important;
    font-weight: 500;

    margin: 5px;
    display: flex;
  }
`;


export const ModalConnect = ({ accounts, setAccounts, showModal, setShowModal, setClean, clean, balanceSea, setBalanceSea, balanceSun, setBalanceSun, balanceMoon, setBalanceMoon, setBalanceAcc, balanceAcc }) => {
  const modalRef = useRef();
  // console.log("desde", balanceMoon)
  const animation = useSpring({
    config: {
      duration: 250
    },
    opacity: showModal ? 1 : 0,
    transform: showModal ? `translateY(-50%)` : `translateY(-100%)`
  });

  const closeModal = e => {
    if (modalRef.current === e.target) {
      setShowModal(false);
    }
  };

  const keyPress = useCallback(
    e => {
      if (e.key === 'Escape' && showModal) {
        setShowModal(false);
        // console.log('I pressed');
      }
    },
    [setShowModal, showModal]
  );
  
  const disconnectWeb3Modal = async () => {
    // await web3Modal.clearCachedProvider();
    window.localStorage.clear();
    setAccounts([]);
  };



  async function connectAccount() {
    if (typeof window.ethereum !== 'undefined' || (typeof window.web3 !== 'undefined')) {
      console.log("Injected Web3 Wallet is installed!");
    const chainId = 0x1;
    const accounts = await window.ethereum.request({
      method: "eth_requestAccounts",
    });      
      if (accounts.length === 0) {
        console.log("Please connect to MetaMask!");
      } else if(chainId !== '0x1') {
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: '0x1' }],
      })
      setAccounts(accounts[0])
      setShowModal(false);
      } 
      } else {
        console.log("Please install metamask");
        alert('Please Install Metamask');
      }
}

const account = (
  accounts[0] +
  accounts[1] +
  accounts[2] +
  accounts[3] +
  accounts[4] +
  accounts[5] +
  "..." +
  accounts[38] +
  accounts[39] +
  accounts[40] +
  accounts[41]
);

  useEffect(
    () => {
      document.addEventListener('keydown', keyPress);
      return () => document.removeEventListener('keydown', keyPress);
    },
    [keyPress]
  );

  return (
    <>
      {showModal ? (
        <Background onClick={closeModal} ref={modalRef}>
          <animated.div style={animation}>
            <ModalWrapper showModal={showModal}>
              <ModalContent>

                <Box color={'rgba(255, 255, 255, 0.7)   '}>
                  <h5>Wallet: {account}</h5>
                </Box>
                <Box color={'rgba(255, 255, 255, 0.7)   '}>
                  <h5>Balance: {balanceAcc} ETH</h5>
                </Box>

                {/* <h5>NFTs Collections</h5> */}

                <Box color={'rgba(255, 255, 255, 0.7)   '}
                    border={'solid'}
                    borderWidth={1} 
                    borderRadius={5}
                    padding={'3px 15px 3px 15px'}
                    width={250}
                    margin={5}>
                      <h6>NFTs Collections</h6>
                      <Box display={"flex"} justifyContent={"space-around"}>
                      
                        <Box color={'rgba(255, 255, 255, 0.7)   '}>
                          <h6>Sea: {balanceSea}</h6>
                        </Box>
                        <Box color={'rgba(255, 255, 255, 0.7)   '}>
                          <h6>Sun: {balanceSun}</h6>
                        </Box>
                        <Box color={'rgba(255, 255, 255, 0.7)   '}>
                          <h6>Moon: {balanceMoon}</h6>
                        </Box>
                      </Box>
                </Box>

                <Box display={"flex"} marginTop="10px">
                  <button  onClick={() => {navigator.clipboard.writeText(accounts)}} type="button" className="btn btn-primary shadow-none">
                  {/* <Button margin="5px" onClick={() => {navigator.clipboard.writeText(accounts).then(() => {alert("successfully copied");})}} type="button" className="btn btn-primary shadow-none"> */}
                    <BiCopy size={'1.3rem'} color={'rgba(255, 255, 255, 0.7)  '}/>   
                    &nbsp;Copy Address
                  </button>
                  <button  onClick={() => {disconnectWeb3Modal(); setShowModal(prev => !prev)}} type="button" className="btn btn-primary shadow-none btn-sm">
                    <FiLogOut size={'1.5rem'} color={'rgba(255, 255, 255, 0.7)  '}/>   
                    &nbsp;Disconnect
                  </button>
                </Box>

              </ModalContent>
            </ModalWrapper>
          </animated.div>
        </Background>
      ) : null}
    </>
  );
};