import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Box, Button, Text, Flex, Image } from '@chakra-ui/react';
import Sea from '../assets/animationsea.gif';
import './Collections.css';
import { Link as RouterLink } from "react-router-dom";


const SeaCollection = () => {
  return (
    <div>
        <Flex className='forltjd5'>
            <Box background={'transparent'} borderRadius="5" className='olfww'>
                <Box textAlign="center" color="rgba(255, 255, 255, 0.7)">
                    <h1 class="sad85dss"><b>SEA</b> Collection</h1>
                </Box>
                <Box color="rgba(255, 255, 255, 0.7)" fontSize="16px" fontWeight="400" fontFamily="Righteous">
                    <Text>
                        SEA is a collection of 1,200 NTFs that aim to unify people with web3.<br></br><br></br>
                        SEA collection grants access to various advantages and benefits within our ecosystem SEA collection. 
                        Besides, Holders can participate in exclusive events, such as NFT claims, raffles, giveaways, and much, much more. 
                        Don't forget that all SEA collection is special, but some are extremely unique.
                    </Text>
                </Box>
                {/* <Box textAlign="center" marginTop="25px">
                    <Button as={RouterLink} to='/mintsea' type="button" class="btn btn-primary shadow-none">Mint Now !</Button>
                </Box> */}
            </Box>
        </Flex>


        <Flex className='askca'>
            {/* <Box className='olrg522'> */}
            <div className='plolff'>
                <Image src={Sea} borderRadius="5" className='orlgid5q'/>
            {/* </Box> */}
            </div>

            <Box className='savvvcs' justifyContent="center">
                <h2 class="h2fx">Utility & Benefits</h2>
                <p class="p12fq">Enjoy a welcome Robiza wine glass in 5 top restaurants and hotels across the island. Check conditions and book the table with us.</p><br></br>
                <p class="p12fq">Ticket for member and up to 2 companions in 3 of the best Night Clubs* on the Island.</p><br></br>
                <p class="p12fq">10% discount to the annual event entry held in a luxury villa in Ibiza: decentralized solutions, wellness and luxury.</p>
                <Box textAlign="center" marginTop="25px">
                    <Button as={RouterLink} to='/mintsea' type="button" class="btn btn-primary shadow-none">Mint Now !</Button>
                </Box>
            </Box>
        </Flex>


    </div>
    );
};

export default SeaCollection;