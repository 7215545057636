import React, { useState, useEffect } from 'react'
// import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Box, Button, Link, Text, Flex, Image } from '@chakra-ui/react';
import Sunset from './assets/animationsun.gif';
import Moon from './assets/animationmoon.gif';
import Sea from './assets/animationsea.gif';
import './Home.css'
import { Link as RouterLink } from "react-router-dom";
import Clock from './components/Clock';

const Home = () => {
    // const [timerDays, setTimerDays] = useState();
    // const [timerHours, setTimerHours] = useState();
    // const [timerMinutes, setTimerMinutes] = useState();
    // const [timerSeconds, setTimerSeconds] = useState();

    // let interval;

    // const startTimer = () => {
    //     const countDownDate = new Date("2023-05-01T00:00Z").getTime();

    //     interval = setInterval(() => {
    //         const now = new Date().getTime();
    //         const distance = countDownDate - now;
            
    //         const days = Math.floor(distance / (24 * 60 * 60 * 1000));
    //         const hours = Math.floor((distance % (24 * 60 * 60 * 1000)) / (1000 * 60 * 60));
    //         const minutes = Math.floor((distance % (60 * 60 * 1000)) / (1000 * 60));
    //         const seconds = Math.floor((distance % (60 * 1000)) / 1000);



    //         if(distance < 0) {
    //             // Stop Timer
    //             // clearInterval(interval.current);
    //             setTimerDays('0');
    //             setTimerHours('0');
    //             setTimerMinutes('0');
    //             setTimerSeconds('0');
    //         } else {
    //             // Update Timer
    //             setTimerDays(days > 9 ? days : '0' + days);
    //             setTimerHours(hours > 9 ? hours : '0'+ hours);
    //             setTimerMinutes(minutes > 9 ? minutes : '0' + minutes);
    //             setTimerSeconds(seconds > 9 ? seconds : '0' + seconds);
    //         }
    //      })
    // }

    // useEffect(() => {
    //     startTimer();
    // });


  return (
    <Box className='clofp22'>
        <Flex className='jcjjcm' justifyContent="center">
            <Box 
                className='bod5s'
                // color="#f3976a" 
                color="white"
                fontFamily="Righteous"
            >
                <Text className='textprinf5' >
                    Welcome<br></br>to<br></br>RobizaWineNFT 
                </Text>
            </Box>
            <Box  className='bod5s2'>
                <Flex justifyContent="center">
                    <Box className='bosimf4' margin="10px"><Image src={Sunset} boxSize="130px" borderRadius="10"/></Box>
                    <Box className='bosimf4' margin="10px"><Image src={Moon} boxSize="130px" borderRadius="10" /></Box>
                    <Box className='bosimf4' margin="10px"><Image src={Sunset} boxSize="130px" borderRadius="10" /></Box>
                    <Box className='bosimf4' margin="10px"><Image src={Sea} boxSize="130px" borderRadius="10" /></Box>
                </Flex>
                <Flex justifyContent="center">
                    <Box className='bosimf4' margin="10px"><Image src={Sea} boxSize="130px" borderRadius="10" /></Box>
                    <Box className='bosimf4' margin="10px"><Image src={Sunset} boxSize="130px" borderRadius="10" /></Box>
                    <Box className='bosimf4' margin="10px"><Image src={Sea} boxSize="130px" borderRadius="10" /></Box>
                    <Box className='bosimf4' margin="10px"><Image src={Moon} boxSize="130px" borderRadius="10" /></Box>
                </Flex>
            </Box>
        </Flex>
                <Box  className='bod5s2ww'>
                        <Flex justifyContent="center">
                            <Box className='bosimf4' margin="10px"><Image src={Sunset} boxSize="130px" borderRadius="10"/></Box>
                            <Box className='bosimf4' margin="10px"><Image src={Moon} boxSize="130px" borderRadius="10" /></Box>
                        </Flex>
                        <Flex justifyContent="center">
                            <Box className='bosimf4' margin="10px"><Image src={Sea} boxSize="130px" borderRadius="10" /></Box>
                            <Box className='bosimf4' margin="10px"><Image src={Sunset} boxSize="130px" borderRadius="10" /></Box>
                        </Flex>    
                    </Box>
        {/* <Box className='olf85esss' background={'rgba(244, 180, 147, 0.05)'}
         borderRadius="10"
         color="rgba(255, 255, 255, 0.7)">
            <Box className='olf85esss1'>
                <h1>MINTING</h1>
            </Box>
            <Box className='olf85esss21'>
                <Box className='oledas'>{timerDays}</Box>
                <Box className='oledas'>{timerHours}</Box>
                <Box className='oledas'>{timerMinutes}</Box>
                <Box className='oledas'>{timerSeconds}</Box>             
            </Box>
            <Box className='olf85esss22'>
                <Box className='oledas'>Days</Box>
                <Box className='oledas'>Hours</Box>
                <Box className='oledas'>Min</Box>
                <Box className='oledas'>Sec</Box>  
                                   
            </Box>
        </Box> */}
        <Clock />
        <Flex justifyContent="center" className='foldss'>
            <Button as={RouterLink} to='/mint' type="button" className="btn btn-primary shadow-none btnfhome">Mint</Button>
            {/* <Link href='https://opensea.io' isExternal style={{ textDecoration: 'none' }}>
                <Button type="button" className="btn btn-primary shadow-none btnfhome">View on OpenSea <br></br>Sea Collection</Button>
            </Link> */}
        </Flex>
    </Box>
  );
};

export default Home;