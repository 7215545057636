import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Box, Button, Text, Flex, Image } from '@chakra-ui/react';
import Moon from '../assets/animationmoon.gif';
import './Collections.css';
import { Link as RouterLink } from "react-router-dom";

const MoonCollection = () => {
  return (
    <div>
        <Flex className='forltjd5'>
            <Box background={'transparent'} borderRadius="5" className='olfww'>
                <Box textAlign="center" color="rgba(255, 255, 255, 0.7)">
                    <h1 class="sad85dss"><b>MOON</b> Collection</h1>
                </Box>
                <Box color="rgba(255, 255, 255, 0.7)" fontSize="16px" fontWeight="400" fontFamily="Righteous">
                    <Text>
                        MOON is a collection of 800 NTFs that aim to unify people with web3.<br></br><br></br>
                        MOON collection grants access to various advantages and benefits within our ecosystem MOON collection. 
                        Besides, Holders can participate in exclusive events, such as NFT claims, raffles, giveaways, and much, much more. 
                        Don't forget that all MOON collection is special, but some are extremely unique.
                    </Text>
                </Box>
            </Box>
        </Flex>

        <Flex className='askca'>
            {/* <Box className='olrg522w'> */}
            <div className='plolff'>
                <Image src={Moon} borderRadius="5" className='orlgid5q'/>
                </div>
            {/* </Box> */}

            <Box className='savvvcs' justifyContent="center">
                <h2 class="h2fx">Utility & Benefits</h2>
                <p class="p12fqq">In addition to the benefits mentioned for the previous SEA & SUN membership, the following are added:</p><br></br>
                <p class="p12fq">Exclusive annual dinner for 2 people in one of the island's renowned restaurants*. *see conditions in the months, reservation to be made, to choose in the restaurants.</p><br></br>
                <p class="p12fq">INVITATION to the annual event held in a luxury villa in Ibiza: decentralized solutions, wellness and luxury.</p><br></br>
                <p class="p12fq">20% discount on Business & Wellness coaching sessions. (Online or face-to-face in Ibiza).</p><br></br>
                <p class="p12fq">10% on the reservation of villas, boat charters and luxury concierge in Ibiza.</p><br></br>
                <p class="p12fq">15% discount of Robiza wine box purchase on our website to your home address*. see conditions.</p><br></br>
                <p class="p12fq">Access to the Robiza Wine store in the metaverse. More info Q3 2023</p>                
                <Box textAlign="center" marginTop="25px">
                    <Button as={RouterLink} to='/mintmoon' type="button" class="btn btn-primary shadow-none">Mint Now !</Button>
                </Box>
            </Box>
        </Flex>
    </div>
    );
};

export default MoonCollection;